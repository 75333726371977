// eslint-disable-next-line import/no-extraneous-dependencies
import { BTr, BTd, BTh } from 'bootstrap-vue'
import { BTable } from './table'
import { BTableLite } from './table-lite'
import { BTableSimple } from './table-simple'
import { BTbody } from './tbody'
import { BThead } from './thead'
import { BTfoot } from './tfoot'
import { pluginFactory } from '../../utils/plugins'

const TableLitePlugin = /* #__PURE__ */ pluginFactory({
  components: {
    BTableLite,
  },
})

const TableSimplePlugin = /* #__PURE__ */ pluginFactory({
  components: {
    BTableSimple,
    BTbody,
    BThead,
    BTfoot,
    BTr,
    BTd,
    BTh,
  },
})

const TablePlugin = /* #__PURE__ */ pluginFactory({
  components: {
    BTable,
  },
  plugins: {
    TableLitePlugin,
    TableSimplePlugin,
  },
})

export {
  // Plugins
  TablePlugin,
  TableLitePlugin,
  TableSimplePlugin,
  // Table components
  BTable,
  BTableLite,
  BTableSimple,
  // Helper components
  BTbody,
  BThead,
  BTfoot,
  BTr,
  BTd,
  BTh,
}
