<template>
  <div
    class="d-flex align-items-center"
    style="gap: 12px"
  >
    <h4
      class="font-weight-bolder mb-0"
      style="min-width: 165px"
    >
      {{ getCurrentDate() }}
    </h4>

    <div
      class="d-flex"
      style="gap: 12px"
    >
      <b-button
        class="chevron-btn--custom"
        @click="prevMonth"
      >
        <feather-icon
          icon="ChevronLeftIcon"
          size="28"
        />
      </b-button>
      <b-button
        class="chevron-btn--custom"
        @click="nextMonth"
      >
        <feather-icon
          icon="ChevronRightIcon"
          size="28"
        />
      </b-button>
    </div>
    <div>
      <b-button
        class="this-month-btn"
        variant="success"
        :disabled="checkThisMonth"
        @click="setCurrentDate"
      >
        {{ $t('This Month') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'

export default {
  name: 'ManageMonthlyDate',
  components: {
    BButton,
  },
  data() {
    return {
      currentMonth: new Date().getMonth(),
      currentYear: new Date().getFullYear(),
      currentDay: new Date().getDay(),
    }
  },
  computed: {
    checkThisMonth() {
      return this.currentMonth === new Date().getMonth() && this.currentYear === new Date().getFullYear()
    },
  },
  methods: {
    prevMonth() {
      const newDate = new Date(this.currentYear, this.currentMonth - 1)
      this.changeDate(newDate)
      this.$emit('prevMonth', { currentMonth: this.currentMonth, currentYear: this.currentYear })
    },
    nextMonth() {
      const newDate = new Date(this.currentYear, this.currentMonth + 1)
      this.changeDate(newDate)
      this.$emit('nextMonth', { currentMonth: this.currentMonth, currentYear: this.currentYear })
    },
    changeDate(newDate) {
      this.currentMonth = newDate.getMonth()
      this.currentYear = newDate.getFullYear()
    },
    getCurrentDate() {
      return new Date(`${this.currentYear}-${this.currentMonth + 1}-${this.currentDay}`).toLocaleString('en-us', { month: 'long', year: 'numeric' })
    },
    setCurrentDate() {
      this.currentMonth = new Date().getMonth()
      this.currentYear = new Date().getFullYear()
      this.$emit('currentMonth', { currentMonth: this.currentMonth, currentYear: this.currentYear })
    },
  },
}
</script>

<style lang="scss" scoped>
.chevron-btn--custom {
  min-width: 36px;
  height: 36px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.this-month-btn {
  min-width: 115px;
  height: 36px;
  padding: 0;

  &:disabled {
    cursor: not-allowed;
  }
}
</style>
