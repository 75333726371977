<template>
  <div>
    <button-dashed
      v-if="(trashListPath || trashListPathObj) && $can(canShowTrashList.action, canShowTrashList.subject)"
      class="dashed-btn-custom-style"
      style="min-width: 33px; min-height: 33px"
      @click="gotoNewPage(trashListIsUri ? trashListPathObj :{ name: trashListPath }, $event)"
    >
      <feather-icon
        icon="LTrashOpenIcon"
        size="16"
      />
    </button-dashed>
    <button-dashed
      v-if="backToListPath"
      class="font-weight-bolder mr-0"
      @click="$router.push({ name: backToListPath }) "
    >
      {{ $t('Back to List') }}
    </button-dashed>
    <button-dashed
      v-if="backUri"
      class="font-weight-bolder mr-0"
      @click="$router.push(backUri) "
    >
      {{ $t('Back to List') }}
    </button-dashed>
  </div>
</template>

<script>
import ButtonDashed from '@core/components/b-button-dashed/ButtonDashed.vue'

export default {
  name: 'SwitcherToTrashFromTrashToList',
  components: {
    ButtonDashed,
  },
  props: {
    trashListPath: {
      type: String,
      required: true,
    },
    backToListPath: {
      type: String,
      required: true,
    },
    trashListIsUri: {
      type: Boolean,
      default: false,
      required: false,
    },
    trashListPathObj: {
      type: Object,
      default: () => {},
    },
    // eslint-disable-next-line vue/require-default-prop
    backUri: {
      type: Object,
      required: false,
    },
    canShowTrashList: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped>

</style>
