export default function config() {
  const actionsCustomer = [
    {
      btnTitle: 'Move to Trash',
      actionType: 'deleteOrTrashButtonHandler',
      method: 'deleteOrTrashButtonHandler',
      confirmNotificationOptions: {
        text: 'It is possible to revert this',
        confirmButtonText: 'Yes, deactivate it',
      },
    },
    {
      btnTitle: 'Export',
      actionType: 'exportDropdown',
      method: 'exportDropdown',
      subActions: [
        {
          btnTitle: 'Customer information',
          actionType: 'exportCustomerInfo',
          method: 'exportCustomerInfo',
        },
        {
          btnTitle: 'Customer addresses',
          actionType: 'exportCustomerAddress',
          method: 'exportCustomerAddress',
        },
        {
          btnTitle: 'Customer contacts',
          actionType: 'exportCustomerContacts',
          method: 'exportCustomerContacts',
        },
      ],
    },
    // {
    //   btnTitle: 'Create order',
    //   actionType: 'createOrder',
    //   method: 'createOrder',
    // },
  ]

  const actionsCatalog = [
    {
      btnTitle: 'Move to Trash',
      actionType: 'deleteOrTrashButtonHandler',
      method: 'deleteOrTrashButtonHandler',
      confirmNotificationOptions: {
        text: 'It is possible to revert this',
        confirmButtonText: 'Yes, deactivate it',
      },
    },
    {
      btnTitle: 'Export',
      actionType: 'exportDropdownCatalog',
      method: 'exportDropdownCatalog',
      subActions: [
        {
          btnTitle: 'Product information',
          actionType: 'exportProductInfo',
          method: 'exportProductInfo',
        },
        {
          btnTitle: 'Product prices',
          actionType: 'exportProductPrices',
          method: 'exportProductPrices',
        },
      ],
    },
    {
      btnTitle: 'Add to Draft Order',
      actionType: 'addToDraftOrder',
      method: 'addToDraftOrder',
    },
    {
      btnTitle: 'Print',
      actionType: 'printCatalog',
      method: 'printCatalog',
    },
  ]

  const actionsInventory = [
    {
      btnTitle: 'Add to new quote',
      actionType: 'addToNewQuoteInventory',
      method: 'addToNewQuote',
    },
    {
      btnTitle: 'Add to existing quote',
      actionType: 'addToExistingQuoteInventory',
      method: 'addToExistingQuote',
    },
    {
      btnTitle: 'Print asset tags',
      actionType: 'printAssetTagsInventory',
      method: 'printAssetTags',
    },
    {
      btnTitle: 'Import',
      actionType: 'importInventory',
      method: 'importInventory',
    },
    {
      btnTitle: 'Export',
      actionType: 'exportDropdownInventory',
      method: 'exportInventory',
    },

  ]
  return {
    actionsCatalog,
    actionsCustomer,
    actionsInventory,
  }
}
