export default {
  tableConfigOptions: {
    type: Object,
    default: () => ({}),
  },
  tableColumns: {
    type: Array,
    required: true,
  },
  isPaginationAvailable: {
    type: Boolean,
    default: () => true,
  },
  moduleName: {
    type: String,
    required: true,
  },
  tableProps: {
    type: Object,
    default: () => ({}),
  },
  createPageUrl: {
    type: [Object, String],
    default: () => '',
  },
  createBtnDashed: {
    type: Boolean,
    default: false,
  },
  canCreate: {
    type: Object,
    default: () => ({ action: 'manage', subject: 'all' }),
  },
  canShowTrashList: {
    type: Object,
    default: () => ({ action: 'manage', subject: 'all' }),
  },
  searchProps: {
    type: Object,
    default: () => ({}),
  },
  searchInputProps: {
    type: Object,
    default: () => ({}),
  },
  isAutoSuggest: {
    type: Boolean,
    default: false,
  },
  autoSuggestAddParams: {
    type: Object,
    default: () => ({}),
  },
  isMobileViewCustom: {
    type: Boolean,
    default: false,
  },
  isDelete: {
    type: Boolean,
    default: false,
  },
  hasAction: {
    type: Boolean,
    default: false,
  },
  filterClass: {
    type: [Object, null],
    default: null,
  },
  isSearchable: {
    type: Boolean,
    default: true,
  },
  rowClicked: {
    type: [Function, String, null],
    default: null,
  },
  tableSuggestionConfigs: {
    type: Array,
    required: false,
    default: () => [],
  },
  isSuggestionTable: {
    type: Boolean,
    default: () => false,
  },
  autoSuggestStyle: {
    type: Object,
    default: () => {},
  },
  isReloadOnSelectSuggestItem: {
    type: Boolean,
    default: true,
  },
  trashListPath: {
    type: String,
    default: '',
  },
  trashListPathObj: {
    type: Object,
    default: () => {},
  },
  actionName: {
    type: String,
    default: '',
  },
  queryParams: {
    type: Object,
    default: () => {},
  },
  confirmNotificationOptions: {
    type: Object,
    default: () => {},
  },
  backToListPath: {
    type: String,
    default: '',
  },
  backUri: {
    type: Object,
    required: false,
  },
  trashListIsUri: {
    type: Boolean,
    default: false,
    required: false,
  },
  sectionConfigs: {
    type: Object,
    default: () => ({
      default: {
        onSelected: null,
      },
    }),
    required: false,
  },
  createBtnIcon: {
    type: String,
    default: 'LAddButtonIcon',
    required: false,
  },
  createBtnDisabled: {
    type: Boolean,
    default: false,
    required: false,
  },
}
