export const NAME_CARD = 'BCard'
export const NAME_DROPDOWN = 'BDropdown'
export const NAME_PAGINATION = 'BPagination'
export const NAME_TABLE = 'BTableResponsive'
export const NAME_TABLE_CELL = 'BTableCell'
export const NAME_TABLE_LITE = 'BTableLite'
export const NAME_TABLE_SIMPLE = 'BTableSimple'
export const NAME_TBODY = 'BTbody'
export const NAME_TBODY_GRID = 'TBodyGrid'
export const NAME_TFOOT = 'BTfoot'
export const NAME_TH = 'BTh'
export const NAME_THEAD = 'BThead'
export const NAME_TR = 'BTr'
