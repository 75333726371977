export const SLOT_NAME_ADD_BUTTON_TEXT = 'add-button-text'
export const SLOT_NAME_APPEND = 'append'
export const SLOT_NAME_ASIDE = 'aside'
export const SLOT_NAME_BADGE = 'badge'
export const SLOT_NAME_BOTTOM_ROW = 'bottom-row'
export const SLOT_NAME_BUTTON_CONTENT = 'button-content'
export const SLOT_NAME_CUSTOM_FOOT = 'custom-foot'
export const SLOT_NAME_DECREMENT = 'decrement'
export const SLOT_NAME_DEFAULT = 'default'
export const SLOT_NAME_DESCRIPTION = 'description'
export const SLOT_NAME_DISMISS = 'dismiss'
export const SLOT_NAME_DROP_PLACEHOLDER = 'drop-placeholder'
export const SLOT_NAME_ELLIPSIS_TEXT = 'ellipsis-text'
export const SLOT_NAME_EMPTY = 'empty'
export const SLOT_NAME_EMPTYFILTERED = 'emptyfiltered'
export const SLOT_NAME_FILE_NAME = 'file-name'
export const SLOT_NAME_FIRST = 'first'
export const SLOT_NAME_FIRST_TEXT = 'first-text'
export const SLOT_NAME_FOOTER = 'footer'
export const SLOT_NAME_HEADER = 'header'
export const SLOT_NAME_HEADER_CLOSE = 'header-close'
export const SLOT_NAME_ICON_CLEAR = 'icon-clear'
export const SLOT_NAME_ICON_EMPTY = 'icon-empty'
export const SLOT_NAME_ICON_FULL = 'icon-full'
export const SLOT_NAME_ICON_HALF = 'icon-half'
export const SLOT_NAME_IMG = 'img'
export const SLOT_NAME_INCREMENT = 'increment'
export const SLOT_NAME_INVALID_FEEDBACK = 'invalid-feedback'
export const SLOT_NAME_LABEL = 'label'
export const SLOT_NAME_LAST_TEXT = 'last-text'
export const SLOT_NAME_LEAD = 'lead'
export const SLOT_NAME_LOADING = 'loading'
export const SLOT_NAME_MODAL_BACKDROP = 'modal-backdrop'
export const SLOT_NAME_MODAL_CANCEL = 'modal-cancel'
export const SLOT_NAME_MODAL_FOOTER = 'modal-footer'
export const SLOT_NAME_MODAL_HEADER = 'modal-header'
export const SLOT_NAME_MODAL_HEADER_CLOSE = 'modal-header-close'
export const SLOT_NAME_MODAL_OK = 'modal-ok'
export const SLOT_NAME_MODAL_TITLE = 'modal-title'
export const SLOT_NAME_NAV_NEXT_DECADE = 'nav-next-decade'
export const SLOT_NAME_NAV_NEXT_MONTH = 'nav-next-month'
export const SLOT_NAME_NAV_NEXT_YEAR = 'nav-next-year'
export const SLOT_NAME_NAV_PEV_DECADE = 'nav-prev-decade'
export const SLOT_NAME_NAV_PEV_MONTH = 'nav-prev-month'
export const SLOT_NAME_NAV_PEV_YEAR = 'nav-prev-year'
export const SLOT_NAME_NAV_THIS_MONTH = 'nav-this-month'
export const SLOT_NAME_NEXT_TEXT = 'next-text'
export const SLOT_NAME_OVERLAY = 'overlay'
export const SLOT_NAME_PAGE = 'page'
export const SLOT_NAME_PLACEHOLDER = 'placeholder'
export const SLOT_NAME_PREPEND = 'prepend'
export const SLOT_NAME_PREV_TEXT = 'prev-text'
export const SLOT_NAME_ROW_DETAILS = 'row-details'
export const SLOT_NAME_TABLE_BUSY = 'table-busy'
export const SLOT_NAME_TABLE_CAPTION = 'table-caption'
export const SLOT_NAME_TABLE_COLGROUP = 'table-colgroup'
export const SLOT_NAME_TABS_END = 'tabs-end'
export const SLOT_NAME_TABS_START = 'tabs-start'
export const SLOT_NAME_TEXT = 'text'
export const SLOT_NAME_THEAD_TOP = 'thead-top'
export const SLOT_NAME_TITLE = 'title'
export const SLOT_NAME_TOAST_TITLE = 'toast-title'
export const SLOT_NAME_TOP_ROW = 'top-row'
export const SLOT_NAME_VALID_FEEDBACK = 'valid-feedback'
export const SLOT_NAME_CARD_SECTION = 'section'
