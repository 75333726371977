<template>
  <div>
    <!-- Main Dropdown -->
    <b-dropdown
      id="dropdown-1"
      size="md"
      :disabled="disabled"
      no-caret
      variant="customClassButton"
      class="custom-export-actions-style paddingCustom"
      @click.stop
    >
      <template #button-content>
        <div
          class="d-flex align-items-center"
          style="gap: 8px"
        >
          <feather-icon :icon="iconName" />
          <div>{{ $t(title) }}</div>
          <feather-icon
            icon="LArrowDownIcon"
            size="11"
            style="position: relative; top: 1px"
          />
        </div>
      </template>

      <!-- Items for the main dropdown -->
      <b-dropdown-item-button
        v-for="item in filteredOrdinaryItems"
        :key="item.actionType"
        @click="handleAction(item, $event)"
      >
        <template>
          {{ item.btnTitle }}
        </template>
      </b-dropdown-item-button>

      <!-- Check if the current item has subActions SECOND part starts-->
      <template v-if="filteredDropDownItems.length > 0">
        <b-dropdown
          id="dropdown-2"
          left
          size="sm"
          :disabled="disabled"
          no-caret
          variant="white"
          class="dropdown-item customDropdown"
        >
          <template #button-content>
            <div
              class="d-flex align-items-center"
              style="gap: 8px"
            >
              <feather-icon :icon="iconName" />
              <div>{{ filteredDropDownItems[0] ? filteredDropDownItems[0].btnTitle : '' }}</div>
              <feather-icon
                icon="LArrowDownIcon"
                size="11"
                style="position: relative; top: 1px"
              />
            </div>
          </template>
          <!-- Use a template v-if to check if filteredDropDownItems[1] exists -->

          <template v-for="subAction in filteredDropDownItems[0].subActions">
            <b-dropdown-item-button
              :key="subAction.actionType"
              @click="handleAction(subAction, $event)"
            >
              <template>
                {{ subAction.btnTitle }}
              </template>
            </b-dropdown-item-button>
          </template>
        </b-dropdown>
      </template>
    <!-- SECOND part ends-->
    </b-dropdown>
  </div>
</template>

<script>
import { BDropdown, BDropdownItemButton } from 'bootstrap-vue'

export default {
  name: 'Actions',
  components: {
    BDropdown,
    BDropdownItemButton,
  },
  props: {
    bDropDownItems: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    iconName: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDropdown2Visible: false,
    }
  },
  computed: {
    filteredDropDownItems() {
      // Filter the array based on the condition in v-if
      return this.bDropDownItems.filter(item => item.subActions)
    },
    filteredOrdinaryItems() {
      // Filter the array based on the condition in v-if
      return this.bDropDownItems.filter(item => !item.subActions)
    },
  },
  mounted() {
    this.$root.$on('bv::dropdown::show', bvEvent => {
      if (bvEvent.componentId === 'dropdown-2') {
        this.isDropdown2Visible = true
      }
    })
    this.$root.$on('bv::dropdown::hide', bvEvent => {
      if (bvEvent.componentId === 'dropdown-2') {
        this.isDropdown2Visible = false
      }
      if (this.isDropdown2Visible) {
        bvEvent.preventDefault()
      }
    })
  },
  methods: {
    handleAction(item, event) {
      event.stopPropagation()
      this.$emit('actionHandler', {
        btnTitle: item.btnTitle,
        actionType: item.actionType,
        method: item.method,
        confirmNotification: item.confirmNotificationOptions,
      })
    },
  },
}
</script>

<style>
.custom-export-actions-style .dropdown-menu {
  padding: 0 !important;
  overflow: unset;
}
.customDropdown{
  padding: 4px;
}
</style>
